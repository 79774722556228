<template>
  <v-container container--fluid grid-list-md pa-5>
    <h3 class="headline logo_text">{{ title }}</h3>
    <v-layout row wrap>
      <v-flex xs12 sm4>
        <add-map-modal>
          <v-hover slot-scope="{ openDialog }">
            <template style="cursor: pointer;" v-slot="{ hover }">
              <v-card @click.stop="openDialog" :elevation="hover ? 24 : 6">
                <v-card-title primary-title>
                  <v-layout justify-space-between>
                    <i class="fa fa-plus-square-o logo_icon" />
                    <span class="logo_text">Add Map</span>
                    &nbsp;
                  </v-layout>
                </v-card-title>
                <v-divider />
                <v-card-text>
                  <p class="caption">Add a new map</p>
                  <button class="hidden-sm-and-up">
                    Add
                  </button>
                </v-card-text>
              </v-card>
            </template>
          </v-hover>
        </add-map-modal>
      </v-flex>
      <v-flex v-for="map in maps" :key="map.title" xs12 sm4>
        <v-hover>
          <template v-slot="{ hover }">
            <v-card
              :elevation="hover ? 24 : 6"
              :to="'/' + page + '/' + map.title"
            >
              <v-card-title primary-title>
                <v-layout justify-space-between>
                  <i class="fa fa-map-o logo_icon" />
                  <span class="logo_text">{{ map.title }}</span>
                  <delete-modal :title="map.title">
                    <i
                      slot-scope="{ openDialog }"
                      @click.stop="openDialog"
                      class="fa fa-trash-o logo_text"
                      @click.prevent
                    />
                  </delete-modal>
                </v-layout>
              </v-card-title>
              <v-divider />
              <v-card-text>
                <p class="caption">Last updated {{ map.lastUpdated }}</p>
                <button class="hidden-sm-and-up">
                  Go
                </button>
              </v-card-text>
            </v-card>
          </template>
        </v-hover>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
const addMapModal = () =>
  import(/* webpackChunkName: "addMapModal" */ "./AddMapModal.vue");
const deleteModal = () =>
  import(/* webpackChunkName: "deleteModal" */ "./Map/DeleteModal.vue");

export default {
  name: "SelectMap",
  components: {
    addMapModal,
    deleteModal
  },
  props: {
    page: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters(["maps"])
  }
};
</script>
