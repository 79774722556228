<template>
  <select-map page="maps" title="Map Editor" />
</template>
<script>
import selectMap from "../components/SelectMap.vue";

export default {
  name: "Maps",
  components: {
    selectMap
  }
};
</script>
